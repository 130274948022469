<template>
  <!-- 潜水咨讯 -->
  <div class="jy_news">
    <div class="jy_news_bg">
      <img :src="region_bg" alt="" style="width:100%" />
    </div>
    <ul class="jy_news_type">
      <a
        :href="'#/jy_news?currentIdx=' + head_active"
        v-for="(item, idx) in sjHead"
        :key="idx"
        :class="[head_active == idx ? 'xz_type' : '']"
        @click="switchList(item, idx)"
      >
        {{ item.name }}
      </a>
    </ul>
    <div class="jy_news_nav" v-if="!currentId">
      <ul class="jy_news_list" v-if="sjList.length != 0">
        <li v-for="(item, idx) in sjList" :key="idx" @click="goDetails(item)">
          <a
            :href="
              '#/jy_news?currentIdx=' + head_active + '&currentId=' + item.id
            "
          >
            <div class="list_image">
              <img
                :src="item.cover_image"
                alt=""
                style="width:100%;"
              />
            </div>
            <div class="list_nav">
              <h2>{{ item.title }}</h2>
              <p style="margin-bottom:0.1rem">{{ item.miaoshu }}</p>
              <p style="color:#a3afb7">
                {{ item.create_time }}
                <i class="el-icon-view" style="margin-left:0.2rem"></i>
                {{ item.reads }}
              </p>
            </div>
            <!-- <div class="list_title ellipsis">{{ item.title }}</div> -->
          </a>
        </li>
        <onLoading :show="loading"></onLoading>
      </ul>
      <div class="details_empty" v-else>
        <img
          src="@/assets/image/xwdt/xwdt-data.webp"
          alt=""
          style="margin:0 auto"
        />
      </div>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="form.page"
          background
          :page-size="12"
          layout="prev, pager, next,total"
          :total="total"
          v-if="total > 12"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
    <div class="jy_news_nav2" v-else>
      <div class="nav2_html" v-html="sjData.maincontent"></div>
    </div>
  </div>
</template>

<script>
import { getXwTypeData, getAllXwData, getXwxqData, getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: { onLoading },
  data () {
    return {
      region_bg: require('@/assets/image/all/jy_bg.png'),
      sjHead: [],
      sjList: [],
      head_active: 0,
      form: {
        page: 1,
        pagesize: 12
      },
      type_id: '',
      project: '',
      total: 0,
      loading: false,
      currentId: 0,
      sjData: {}
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.head_active = this.$route.query.currentIdx
        } else {
          this.head_active = 0
          this.type_id = ''
          this.getSjdqList(1, '', this.project)
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (this.$route.query.currentIdx) {
        this.head_active = this.$route.query.currentIdx
      } else {
        this.head_active = 0
      }
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.goDetails({ id: this.currentId })
      } else {
        this.currentId = 0
      }
      let id = sessionStorage.getItem(this.changeData() + 'id')
      that.project = sessionStorage.getItem(this.changeData() + 'project')
      getXwTypeData(1, that.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          that.sjHead = res.data.data
        }
      })
      that.getSjdqList(1, '', that.project)
    },
    getSjdqList (i, x, d) {
      this.loading = true
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.sjList = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.getSjdqList(val, this.type_id, this.project)
    },
    switchList (e, i) {
      this.head_active = i
      this.currentId = 0
      this.type_id = e.id
      this.getSjdqList(1, e.id, this.project)
    },
    goDetails (e) {
      this.currentId = e.id
      this.sjData = {}
      getXwxqData(e.id, this.form.project).then(res => {
        if (res.data.code == 1) {
          this.sjData = res.data.data
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.jy_news {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .jy_news_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
    img {
      animation: scaleAnim 3s ease forwards;
    }
  }
  .jy_news_type {
    width: 15rem;
    background-color: #fff;
    margin: 3% auto;
    margin-bottom: 2%;
    border-bottom: 1px solid #0a8ce2;
    text-align: center;
    display: flex;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      font-size: 0.18rem;
      color: #333333;
      position: relative;
      text-align: center;
      width: 1.5rem;
      padding: 0.3rem 0.15rem;
      cursor: pointer;
    }
    a:hover,
    .xz_type {
      background-color: #0a8ce2;
      color: #fff;
    }
  }
  .jy_news_list,
  .details_empty {
    width: calc(15rem - 0.8rem);
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
    padding: 0.4rem;
    li {
      border-bottom: 1px solid #f0f2f5;
      padding-bottom: 0.3rem;
      margin-bottom: 0.3rem;
      display: inline-block;
      font-size: 0.18rem;
      vertical-align: middle;
      width: 100%;
      cursor: pointer;
      a {
        display: flex;
      }
      .list_image {
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 0.2rem;
      }
      .list_nav {
        width: calc(100% - 2.7rem);
        line-height: 1.5;
        h2 {
          margin: 0 0 0.2rem;
          font-size: 0.3rem;
        }
        h2:hover {
          color: #0a8ce2;
        }
      }
    }
  }
  .details_empty {
    width: 15rem;
    background-color: #fff;
  }
  .jy_news_nav2 {
    width: 15.2rem;
    margin: 50px auto;
    .nav2_html {
      line-height: 1.5;
      /deep/img {
        margin: 0 auto;
        display: inline-block;
      }
    }
  }
  .all-pagination {
    padding-bottom: 35px;
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
